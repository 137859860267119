import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import React, {useCallback, useMemo, useState} from 'react';
import {
  ForcedUnlockButton,
  ReservationButton,
  PaymentStatusButton,
} from './reservationButton';
import {Switch, Route, useHistory} from 'react-router-dom';
import {Chats} from '@src/pages/Chats';
import ReservationPaymentInfoModal from '@src/pages/Reservations/ReservationPaymentInfoModal';
import {Box} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';

moment.tz.setDefault('Asia/Tokyo');

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: '1rem',
  },
}))(Tooltip);

export const ReservationRow = React.memo((
  {
    reservation = {port: {}, door: {}, babyCar: {}, issuedCoupon: {coupon: {}}},
    index = 0,
    unlock,
    adminLock,
    finish,
    updateReservation,
    isSystemAdmin,
    adminLockReservation,
    createChat,
  }: any) => {
  const history = useHistory();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const paidFailMsg = useMemo(() => {
    if (reservation.paymentHistories?.length > 0) {
      return reservation.paymentHistories.some((ph) => ph.status === 'FAILED')
        ? '部分決済失敗'
        : '';
    }
    return '';
  }, [reservation.paymentHistories]);

  const userChatID = useMemo(() => reservation.user.chats?.map((chat) => chat.id)?.[0] || '', [reservation.user.chats]);

  const handleFormatPriceJapan = useCallback((price) => {
    return price?.toLocaleString('en-US') || '0';
  }, []);

  const selectUserFn = useCallback(async () => {
    if (!reservation.user.chats || reservation.user.chats.length === 0) {
      const {data} = await createChat(reservation.user.id);
      const chatId = data?.createChat?.id;
      if (chatId) {
        history.push(`/chats/${chatId}`);
      }
      return;
    }
    if (userChatID) {
      history.push(`/chats/${userChatID}`);
    }
  }, [createChat, history, reservation.user.id, userChatID]);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleGoCustomerPage = useCallback(() => {
    history.push('/customers', {params: `${reservation?.user?.firstname}${reservation?.user?.lastname}`});
  }, [history, reservation?.user?.firstname, reservation?.user?.lastname]);

  const fullName = `${reservation?.user?.lastname}${reservation?.user?.firstname}`;
  const taxedAmountWithCoupon = reservation.issuedCoupon
    ? reservation.taxedAmount + reservation.issuedCoupon.coupon.discountAmount
    : reservation.taxedAmount;

  return (
    <TableRow key={reservation.id}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        {reservation.user.deletedAt ? (
          fullName
        ) : (
          <>
            <Button onClick={handleClick}>{fullName}</Button>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            >
              <Box sx={{display: 'flex', flexDirection: 'column'}} width={100}>
                <Button onClick={handleGoCustomerPage}>詳細</Button>
                <Button onClick={selectUserFn} disabled={reservation.user.deletedAt}>チャット</Button>
              </Box>
            </Popover>
          </>
        )}
        <Switch>
          <Route exact path="/chats/:userChatID">
            <Chats reservation={reservation}/>
          </Route>
        </Switch>
      </TableCell>
      <TableCell style={{maxWidth: 200}}>
        <LightTooltip title={reservation?.startPort?.name || ''} placement="bottom">
          <Typography style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {reservation?.startPort?.name}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell>
        <LightTooltip title={reservation?.startDoor?.doorIndex || ''} placement="bottom">
          <Typography style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {reservation?.startDoor?.doorIndex}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell style={{maxWidth: 200}}>
        <LightTooltip title={reservation?.port?.name || ''} placement="bottom">
          <Typography style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {reservation?.port?.name}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell>
        <LightTooltip title={reservation?.door?.doorIndex || ''} placement="bottom">
          <Typography style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {reservation?.door?.doorIndex}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell>
        <ReservationButton reservation={reservation} unlock={unlock} adminLock={adminLock}/>
      </TableCell>
      <TableCell style={{color: reservation.status ? 'green' : 'red'}}>
        {reservation.status ? '返却済' : '貸出中'}
      </TableCell>
      <TableCell>
        <ForcedUnlockButton reservation={reservation} adminLockReservation={adminLockReservation}/>
      </TableCell>
      <TableCell style={{maxWidth: 130}}>
        <LightTooltip title={`${handleFormatPriceJapan(taxedAmountWithCoupon)} 円`} placement="bottom">
          <Typography style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {handleFormatPriceJapan(taxedAmountWithCoupon)} 円
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell id={reservation.id} style={{maxWidth: 200}}>
        <LightTooltip title={reservation.issuedCoupon?.coupon?.name || ''} placement="bottom">
          <Typography
            style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
            onClick={() => setPopoverOpen(true)}
          >
            {reservation.issuedCoupon?.coupon?.name || ''}
          </Typography>
        </LightTooltip>
        <Popover
          anchorEl={document.getElementById(reservation.id)}
          open={popoverOpen}
          onClose={() => setPopoverOpen(false)}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          <Typography>{reservation.issuedCoupon?.coupon?.name || ''}</Typography>
        </Popover>
      </TableCell>
      <TableCell>
        <PaymentStatusButton
          paidFailMsg={paidFailMsg}
          reservation={reservation}
          updateReservation={updateReservation}
        />
      </TableCell>
      <TableCell style={{color: reservation.issuedCoupon ? 'red' : '', maxWidth: 150}}>
        <LightTooltip title={`${handleFormatPriceJapan(reservation.taxedAmount)} 円`} placement="bottom">
          <Typography style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {handleFormatPriceJapan(reservation.taxedAmount)} 円
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell style={{maxWidth: 200}}>
        <LightTooltip title={moment(reservation.startTime).format('YYYY/MM/DD HH:mm:ss')} placement="bottom">
          <Typography style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {moment(reservation.startTime).format('YYYY/MM/DD HH:mm:ss')}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell style={{maxWidth: 200}}>
        <LightTooltip title={reservation.returnTime ? moment(reservation.returnTime).format('YYYY/MM/DD HH:mm:ss') : ''}
                      placement="bottom">
          <Typography style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {reservation.returnTime ? moment(reservation.returnTime).format('YYYY/MM/DD HH:mm:ss') : ''}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell>
        <LightTooltip title={reservation.user.phoneNo || ''} placement="bottom">
          <Typography style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {reservation.user.phoneNo || ''}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell>
        <LightTooltip title={reservation.product?.name || ''} placement="bottom">
          <Typography style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {reservation.product?.name || ''}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell>
        <LightTooltip title={reservation.extendedTimes >= 0 ? reservation.extendedTimes : ''} placement="bottom">
          <Typography style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {reservation.extendedTimes >= 0 ? reservation.extendedTimes : ''}
          </Typography>
        </LightTooltip>
      </TableCell>
      <TableCell>
        <ReservationPaymentInfoModal isHaveFailPaid={paidFailMsg !== ''} reservationId={reservation.id}/>
      </TableCell>
      <TableCell>
        {isSystemAdmin && !reservation.status ? (
          <Button variant="contained" color="primary" onClick={() => finish(reservation.id)}>
            FINISH
          </Button>
        ) : null}
      </TableCell>
    </TableRow>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.reservation === nextProps.reservation &&
    prevProps.index === nextProps.index &&
    prevProps.unlock === nextProps.unlock &&
    prevProps.adminLock === nextProps.adminLock &&
    prevProps.finish === nextProps.finish &&
    prevProps.updateReservation === nextProps.updateReservation &&
    prevProps.isSystemAdmin === nextProps.isSystemAdmin &&
    prevProps.adminLockReservation === nextProps.adminLockReservation &&
    prevProps.createChat === nextProps.createChat
  );
});