import CircularProgress from '@material-ui/core/CircularProgress';
import {CouponsListComponent} from "@src/components/Coupons/CouponsListComponent";
import gql from "graphql-tag";
import {MySnackBar} from "@src/components/MySnackBar";
import {compose, graphql} from "react-apollo";

const CouponsPage = (props: any) => {
	const {
		adminAddCoupon,
		adminUpdateCoupon,
		adminDeleteCoupon,
		adminRecoverCoupon,
		showAlert,
		data: {
			loading,
			coupons,
			error,
			refetch
		}
	} = props;

	if (loading) return <CircularProgress/>;
	if (error)
		return (
			<MySnackBar variant="error" message={error?.graphQLErrors[0]?.message}/>
		);
	return (
		<CouponsListComponent coupons={coupons}
		                      refetch={refetch}
		                      showAlert={showAlert}
		                      adminAddCoupon={adminAddCoupon}
		                      adminUpdateCoupon={adminUpdateCoupon}
		                      adminRecoverCoupon={adminRecoverCoupon}
		                      adminDeleteCoupon={adminDeleteCoupon}/>
	);
};

const couponsQuery = gql`
  query coupons {
    coupons {
      id
			name
			couponCode
			discountRate
			discountAmount
			discountMinutes
			expStartTime
			expEndTime
			useLimit
			createdAt
			updatedAt
			deletedAt
#	    nameEn
#	    nameKr
#	    nameCn
#	    nameCnTraditional
#	    nameVn
    }
  }
`;

const adminAddCoupon = gql`
	mutation adminAddCoupon(
	  $name: String!,
    $couponCode: String!,
    $discountRate: Int,
    $discountAmount: Int,
    $discountMinutes: Int,
    $useLimit: Int!,
    $expStartTime: DateTime,
    $expEndTime: DateTime,
#		$nameEn: String,
#		$nameKr: String,
#		$nameCn: String,
#		$nameCnTraditional: String,
#		$nameVn: String
	) {
    adminAddCoupon(
				name: $name,
				couponCode: $couponCode,
				discountRate: $discountRate,
				discountAmount: $discountAmount,
				discountMinutes: $discountMinutes,
				useLimit: $useLimit,
				expStartTime: $expStartTime,
				expEndTime: $expEndTime,
#				nameEn: $nameEn,
#				nameKr: $nameKr,
#				nameCn: $nameCn,
#				nameCnTraditional: $nameCnTraditional,
#				nameVn: $nameVn,
      )
	}
`
const adminUpdateCoupon = gql`
	mutation adminUpdateCoupon(  
			$id: ID!,
      $name: String!,
      $couponCode: String!,
      $discountRate: Int,
      $discountAmount: Int,
      $discountMinutes: Int,
      $useLimit: Int!,
      $expStartTime: DateTime,
      $expEndTime: DateTime,
#			$nameEn: String,
#			$nameKr: String,
#			$nameCn: String,
#			$nameCnTraditional: String,
#			$nameVn: String
	) {
      adminUpdateCoupon(
        id: $id,
				name: $name,
				couponCode: $couponCode,
				discountRate: $discountRate,
				discountAmount: $discountAmount,
				discountMinutes: $discountMinutes,
				useLimit: $useLimit,
				expStartTime: $expStartTime,
				expEndTime: $expEndTime,
#	      nameEn: $nameEn,
#	      nameKr: $nameKr,
#	      nameCn: $nameCn,
#	      nameCnTraditional: $nameCnTraditional,
#	      nameVn: $nameVn,
      )
	}
`
const adminDeleteCoupon = gql`
	mutation adminDeleteCoupon($id: ID!) {
		adminDeleteCoupon(id: $id)
	}
`

const adminRecoverCoupon = gql`
	mutation adminRecoverCoupon($id: ID!) {
		adminRecoverCoupon(id: $id)
	}
`

export const Coupons = compose(
	graphql(adminAddCoupon, {
		name: `adminAddCoupon`,
	}),
	graphql(adminUpdateCoupon, {
		name: `adminUpdateCoupon`,
	}),
	graphql(adminDeleteCoupon, {
		name: `adminDeleteCoupon`,
	}),
	graphql(adminRecoverCoupon, {
		name: `adminRecoverCoupon`,
	}),
	graphql(couponsQuery, {
		options: {fetchPolicy: 'network-only'}
	})
)(CouponsPage);
