import {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import {TextField} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {Search as SearchIcon} from '@material-ui/icons';
import moment from "moment/moment";
import {PlusOutlined} from "@ant-design/icons";
import {Button as ButtonAntd, Table, TableColumnsType} from 'antd';
import Button from '@material-ui/core/Button';
import useWindowDimensions from "@src/hooks/useWindowDimensions";
import {CouponModal} from "@src/components/Coupons/CouponModal";

export type CouponRowType = {
	id?: string;
	name: string;
	couponCode: string;
	discountRate?: number;
	discountAmount?: number;
	discountMinutes?: number;
	expStartTime: any;
	expEndTime: any;
	useLimit: number;
	// nameEn: string;
	// nameKr: string;
	// nameCn: string;
	// nameCnTraditional: string;
	// nameVn: string;
}

export type CouponListType = {
	coupons: CouponRowType[];
	refetch: any,
	adminAddCoupon: any,
	adminUpdateCoupon: any,
	adminDeleteCoupon: any,
	adminRecoverCoupon: any,
	showAlert: any
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100vh'
	},
	toolbar: {
		display: 'flex'
	},
	contentPanel: {
		flex: '1 1 100%',
		alignItems: 'center'
	},
	form: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: theme.spacing(1)
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
		maxWidth: 300
	},
	iconButton: {
		padding: 10,
	}
}));


export const  CouponsListComponent = (
	{
		coupons = [],
		refetch,
		adminAddCoupon,
		adminUpdateCoupon,
		adminDeleteCoupon,
		adminRecoverCoupon,
		showAlert
	}: CouponListType): JSX.Element => {
	const classes = useStyles();
	const {width} = useWindowDimensions();
	const [searchParams, setSearchParams] = useState<any>({
		name: '',
		couponCode: '',
	});
	const [state, setState] = useState<CouponRowType>({
		id: '',
		name: '',
		// nameEn: '',
		// nameKr: '',
		// nameCn: '',
		// nameCnTraditional: '',
		// nameVn: '',
		couponCode: '',
		discountRate: 0,
		discountAmount: 0,
		discountMinutes: 0,
		expStartTime: '',
		expEndTime: '',
		useLimit: 0,
	});
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [select, setSelect] = useState('discountAmount');
	const [errorCompareTime, setErrorCompareTime] = useState<string>('')

	const handleSearch = (event) => {
		event.preventDefault();
		setSearchParams({...searchParams});
	}

	const handleChange = (event) => {
		const {target} = event;
		searchParams[target.name] = target.value;
	}

	const filterCoupons = (coupons: CouponRowType[]) => {
		return coupons.filter(val =>
			val.name?.includes(searchParams.name) &&
			val.couponCode?.includes(searchParams.couponCode)
		)
	}

	const handleOpen = (data?: CouponRowType) => {
		if (data) {
			setState(data)
			if (data.discountRate !== 0) {
				setSelect('discountRate')
			} else if (data.discountAmount !== 0) {
				setSelect('discountAmount')
			} else if (data.discountMinutes !== 0) {
				setSelect('discountMinutes')
			}
		}
		setIsModalOpen(true);
	}

	const handleClose = () => {
		setIsModalOpen(false);
		setState({
			id: '',
			name: '',
			// nameEn: '',
			// nameKr: '',
			// nameCn: '',
			// nameCnTraditional: '',
			// nameVn: '',
			couponCode: '',
			discountRate: 0,
			discountAmount: 0,
			discountMinutes: 0,
			expStartTime: '',
			expEndTime: '',
			useLimit: 0,
		})
		setSelect('discountAmount')
		setErrorCompareTime('')
	}

	const handleAddCoupon = async () => {
		const expStartTime = moment(state.expStartTime);
		const expEndTime = moment(state.expEndTime);

		if (expStartTime.isAfter(expEndTime)) {
			setErrorCompareTime('クーポン有効期限日はクーポン適用日により後の日付にする必要があります');
			return;
		} else {
			setErrorCompareTime('')
		}

		let data: CouponRowType = {
			name: state.name,
			couponCode: state.couponCode,
			expStartTime: moment.utc(state.expStartTime),
			expEndTime: moment.utc(state.expEndTime),
			useLimit: +state.useLimit,
			// nameEn: state.nameEn,
			// nameKr: state.nameKr,
			// nameCn: state.nameCn,
			// nameCnTraditional: state.nameCnTraditional,
			// nameVn: state.nameVn,
		}
		if (select === 'discountAmount' && state.discountAmount) {
			data = {...data, discountAmount: +state?.discountAmount, discountMinutes: 0, discountRate: 0}
		} else if (select === 'discountMinutes' && state.discountMinutes) {
			data = {...data, discountMinutes: +state?.discountMinutes, discountAmount: 0, discountRate: 0}
		} else if (select === 'discountRate' && state.discountRate) {
			data = {...data, discountRate: +state?.discountRate, discountAmount: 0, discountMinutes: 0}
		}
		try {
			await adminAddCoupon({
				variables: data
			});
			await refetch();
			handleClose();
		} catch (error) {
			showAlert(error.message.replace('GraphQL error: ', ''), 'error');
		}
	}

	const handleUpdateCoupon = async () => {
		const expStartTime = moment(state.expStartTime);
		const expEndTime = moment(state.expEndTime);

		if (expStartTime.isAfter(expEndTime)) {
			setErrorCompareTime('クーポン有効期限日はクーポン適用日により後の日付にする必要があります');
			return;
		} else {
			setErrorCompareTime('')
		}
		let data: CouponRowType = {
			id: state.id,
			name: state.name,
			couponCode: state.couponCode,
			expStartTime: moment.utc(state.expStartTime),
			expEndTime: moment.utc(state.expEndTime),
			useLimit: +state.useLimit,
			// nameEn: state.nameEn,
			// nameKr: state.nameKr,
			// nameCn: state.nameCn,
			// nameCnTraditional: state.nameCnTraditional,
			// nameVn: state.nameVn,
		}
		if (select === 'discountAmount' && state.discountAmount) {
			data = {...data, discountAmount: +state?.discountAmount, discountMinutes: 0, discountRate: 0}
		} else if (select === 'discountMinutes' && state.discountMinutes) {
			data = {...data, discountMinutes: +state?.discountMinutes, discountAmount: 0, discountRate: 0}
		} else if (select === 'discountRate' && state.discountRate) {
			data = {...data, discountRate: +state?.discountRate, discountAmount: 0, discountMinutes: 0}
		}
		try {
			await adminUpdateCoupon({
				variables: data
			});
			await refetch();
			handleClose();
		} catch (error) {
			showAlert(error.message.replace('GraphQL error: ', ''), 'error');
		}
	}

	const handleDeleteCoupon = async () => {
		try {
			await adminDeleteCoupon({
				variables: {
					id: state.id
				}
			});
			await refetch();
			handleClose();
		} catch (error) {
			showAlert(error.message.replace('GraphQL error: ', ''), 'error');
		}
	}

	const handleRecoverCoupon = async () => {
		try {
			await adminRecoverCoupon({
				variables: {
					id: state.id
				}
			});
			await refetch();
			handleClose();
		} catch (error) {
			showAlert(error.message.replace('GraphQL error: ', ''), 'error');
		}
	}

	const onChangeHandler = (e: any) => {
		if (e.target.name === 'expEndTime') {
			const expStartTime = moment(state.expStartTime);
			const expEndTime = moment(e.target.value);

			if (expStartTime.isAfter(expEndTime)) {
				setErrorCompareTime('クーポン有効期限日はクーポン適用日により後の日付にする必要があります');
			} else {
				setErrorCompareTime('')
			}
		}
		if (e.target.name === 'expStartTime' && state.expEndTime) {
			const expStartTime = moment(e.target.value);
			const expEndTime = moment(state.expEndTime);

			if (expStartTime.isAfter(expEndTime)) {
				setErrorCompareTime('クーポン有効期限日はクーポン適用日により後の日付にする必要があります');
			} else {
				setErrorCompareTime('')
			}
		}
		setState((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const columns: TableColumnsType<any> = [
		{
			title: 'クーポン名',
			key: 'name',
			dataIndex: 'name',
			fixed: 'left',
			sorter: {
				compare: (a, b) => {
					const nameA = a?.name || '';
					const nameB = b?.name || '';
					return  nameA.localeCompare(nameB)
				}
			},
		},
		{
			title: 'クーポンコード',
			key: 'couponCode',
			dataIndex: 'couponCode',
			fixed: 'left',
			sorter: {
				compare: (a, b) => {
					const couponCodeA = a?.couponCode || '';
					const couponCodeB = b?.couponCode || '';
					return couponCodeA.localeCompare(couponCodeB)
				}
			},
		},
		// {
		// 	title: ' クーポン名（英語）',
		// 	key: 'nameEn',
		// 	dataIndex: 'nameEn',
		// },
		// {
		// 	title: ' クーポン名（韓国語）',
		// 	key: 'nameKr',
		// 	dataIndex: 'nameKr',
		// },
		// {
		// 	title: ' クーポン名（中国語：簡体字）',
		// 	key: 'nameCn',
		// 	dataIndex: 'nameCn',
		// },
		// {
		// 	title: ' クーポン名（中国語：繁体字）',
		// 	key: 'nameCnTraditional',
		// 	dataIndex: 'nameCnTraditional',
		// },
		// {
		// 	title: ' クーポン名（ベトナム語）',
		// 	key: 'nameVn',
		// 	dataIndex: 'nameVn',
		// },
		{
			title: '割引率',
			key: 'discountRate',
			dataIndex: 'discountRate',
			sorter: {
				compare: (a, b) => a?.discountRate - b?.discountRate,
			},
		},
		{
			title: '値引金額',
			key: 'discountAmount',
			dataIndex: 'discountAmount',
			sorter: {
				compare: (a, b) => a?.discountAmount - b?.discountAmount,
			},
		},
		{
			title: '無料時間（分）',
			key: 'discountMinutes',
			dataIndex: 'discountMinutes',
			sorter: {
				compare: (a, b) => a?.discountMinutes - b?.discountMinutes,
			},
		},
		{
			title: '利用回数',
			key: 'useLimit',
			dataIndex: 'useLimit',
			sorter: {
				compare: (a, b) => a?.useLimit - b?.useLimit,
			},
		},
		{
			title: 'クーポン適用日',
			key: 'expStartTime',
			dataIndex: 'expStartTime',
			render: (record: any) => {
				return record
					? `${moment.utc(record).format('YYYY/MM/DD HH:mm:ss')}`
					: ''
			},
			sorter: {
				compare: (a, b) => moment(a?.expStartTime).unix() - moment(b?.expStartTime).unix()
			},
		},
		{
			title: 'クーポン有効期限日',
			key: 'expEndTime',
			dataIndex: 'expEndTime',
			render: (record: any) => {
				return record
					? `${moment.utc(record).format('YYYY/MM/DD HH:mm:ss')}`
					: ''
			},
			sorter: {
				compare: (a, b) => moment(a?.expEndTime).unix() - moment(b?.expEndTime).unix()
			},
		},
		{
			title: 'ステータス',
			key: 'status',
			dataIndex: 'status',
			render: (_: any, data: any) => {
				const now = moment();
				const expEndTime = moment(data?.expEndTime);
				if (data?.deletedAt) {
					return '削除済み';
				} else if (now.isBefore(expEndTime)) {
					return '有効';
				}
				return '期限切れ';
			},
			sorter: {
				compare: (a: any, b: any) => {
					const now = moment();
					const expEndTime_a = moment(a?.expEndTime);
					const expEndTime_b = moment(b?.expEndTime);
					if (a?.deletedAt) {
						a = {...a, status: '削除済み'}
					} else if (now.isBefore(expEndTime_a)) {
						a = {...a, status: '有効'}
					} else {
						a = {...a, status: '期限切れ'}
					}
					if (b?.deletedAt) {
						b = {...b, status: '削除済み'}
					} else if (now.isBefore(expEndTime_b)) {
						b = {...b, status: '有効'}
					} else {
						b = {...b, status: '期限切れ'}
					}
					return a?.status.localeCompare(b?.status)
				}
			},
		},
		{
			title: ' ',
			fixed: 'right',
			key: 'action',
			render: (record) => {
				return <ButtonAntd
					type="primary"
					onClick={() => handleOpen(record)}>
					編集
				</ButtonAntd>;
			},
		}
	]

	return (
		<div className={classes.root}>
			<div className={classes.toolbar}>
				<div className={classes.contentPanel}>
					<form className={classes.form} onSubmit={handleSearch}>
						<FormControl className={classes.input}>
							<TextField name='name'
							           label='クーポン名'
							           variant='outlined'
							           InputLabelProps={{shrink: true}}
							           onChange={handleChange}
							/>
						</FormControl>
						<FormControl className={classes.input}>
							<TextField name='couponCode'
							           label='クーポンコード'
							           variant='outlined'
							           InputLabelProps={{shrink: true}}
							           onChange={handleChange}
							/>
						</FormControl>
						<IconButton type='submit' className={classes.iconButton} aria-label="search">
							<SearchIcon/>
						</IconButton>
						<Button
							onClick={() => handleOpen(undefined)}
							style={{height: '100%', boxShadow: 'none', whiteSpace: 'nowrap', fontSize: width < 700 ? '11px' : ''}}
							variant="contained"
							color="primary">
							<PlusOutlined style={{marginRight: '6px'}}/> クーポン作成
						</Button>
					</form>
				</div>
			</div>
			<Table
				bordered
				scroll={{ x: 'max-content' }}
				rowKey={(record) => record?.couponCode}
				dataSource={filterCoupons(coupons)}
				pagination={{ position: ['bottomCenter'] }}
				columns={columns}/>
			<CouponModal state={state}
			             setState={setState}
			             isModalOpen={isModalOpen}
			             addCoupon={handleAddCoupon}
			             updateCoupon={handleUpdateCoupon}
			             deleteCoupon={handleDeleteCoupon}
			             recoverCoupon={handleRecoverCoupon}
			             handleClose={handleClose}
			             onChangeHandler={onChangeHandler}
			             select={select}
			             setSelect={setSelect}
			             errorCompareTime={errorCompareTime}

			/>
		</div>
	);
};