import AWS from 'aws-sdk';
import { Form, Input, Button, message, Upload } from 'antd';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {HelpQuestion, MessageType, Role} from '@src/generated/graphql';
import {
  MessageList,
  MessageType as elementsMessageType,
} from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import {SendOutlined, UploadOutlined} from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { useS3Image } from '@src/hooks/useS3Image';
import { Buffer } from 'buffer';
import imageCompression from 'browser-image-compression';
import {makeStyles} from '@material-ui/core/styles';
import "./style.less";
import { Link } from "react-router-dom";

const { TextArea } = Input;

type chatImageType = {
  key: string | null | undefined;
  imageDataSource: string;
};

export type SingleChatProps = {
  messages: {
    id: any;
    user: {
      id: string;
      firstname: string;
      lastname: string;
      profileImageUrl?: string | null;
      role: Role | null;
    };
    text: string;
    createdAt: string;
    imageContent?: {
      imageKey: string | null;
      imageBucket: string | null;
    } | null;
  }[];
  chatId: string;
  receiveMessage?: any;
  sendMessage: (v: {
    variables: { chatId: string; text: string; messageType: MessageType };
  }) => Promise<void>;
};
const onFinishFailed = (errorInfo: any): void => {
  console.log('Failed:', errorInfo);
};

export const SingleChat = ({
  messages,
  chatId,
  sendMessage,
}: SingleChatProps): JSX.Element => {
  const { uploadS3Image } = useS3Image();
  const s3 = new AWS.S3();
  const [form] = Form.useForm();
  const [chatImageArr, setChatImageArr] = useState<chatImageType[] | null>([]);
  const messageListReferance = useRef<any>(null);

  useEffect(() => {
    const scroll =
      messageListReferance?.current?.scrollHeight -
      messageListReferance?.current?.clientHeight;
    messageListReferance?.current?.scrollTo(0, scroll);
  }, [messageListReferance]);

  const onSend = async (e) => {
    const { message } = e;
    await sendMessage({
      variables: {
        chatId,
        text: message,
        messageType: MessageType.TEXT,
      },
    });
    form.resetFields();
    localStorage.removeItem(`${chatId}_messageKey`);
  };
  const customerAvatarUrl =
    'https://s3-ap-northeast-1.amazonaws.com/share-buggy/profilePicture.png';

  const imageProps: UploadProps = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file) {
      return new Promise(() => {
        const reader = new FileReader();
        const options = {
          maxSizeMB: 0.2,
          maxWidthOrHeight: 520,
          useWebWorker: true,
        };
        imageCompression(file, options).then((compressedFile) => {
          reader.readAsDataURL(compressedFile);
          reader.onload = () => {
            const img = document.createElement('img');
            img.src = reader.result as string;
            uploadS3Image({
              uri: img.src,
              meId: messages[0]?.user?.id ? messages[0]?.user?.id : chatId,
              chatId: chatId,
              isPublic: false,
            });
          };
        });
      });
    },
  };

  let messagesImageArr: chatImageType[] = [];
  useEffect(() => {
    messages.map((m) => {
      let base64ImageDataSource: string = '';
      if (m.imageContent?.imageKey && m.imageContent?.imageBucket) {
        const params = {
          Key: m.imageContent?.imageKey,
          Bucket: m.imageContent?.imageBucket,
        };
        s3.getObject(params, (err, data) => {
          let imageData: AWS.S3.GetObjectOutput | undefined = {};

          if (err) {
            console.log('Err: getObject failed :' + err);
          } else {
            imageData = data;
            const buffer = Buffer.from(imageData.Body as any);
            const base64ImageData = buffer.toString('base64');
            base64ImageDataSource = 'data:image/png;base64,' + base64ImageData;
            messagesImageArr.push({
              key: m.imageContent?.imageKey,
              imageDataSource: base64ImageDataSource,
            });
            if (chatImageArr) {
              setChatImageArr([...chatImageArr, ...messagesImageArr]);
            } else {
              setChatImageArr(messagesImageArr);
            }
          }
        });
      }
    });
  }, [messages]);

  const formattedMessages = () => {
    return messages.map((m, i) => {
      const imageUrl = chatImageArr?.find(
        (img) => img.key === m.imageContent?.imageKey,
      );
      const dateString = moment(m.createdAt).format('YYYY/MM/DD HH:mm');
      return {
        id: i,
        position: m.user.role === Role.SYSTEM_ADMIN ? 'right' : 'left',
        title: m.user.role === Role.SYSTEM_ADMIN ? 'Admin' : 'Customer',
        titleColor: m.user.role === Role.SYSTEM_ADMIN ? '#191970' : '#FF979A',
        text: m.text,
        type: m.text ? 'text' : 'photo',
        date: new Date(),
        dateString: dateString,
        avatar:
          m.user.role === Role.SYSTEM_ADMIN ? undefined : customerAvatarUrl,
        data: {
          uri: imageUrl?.imageDataSource,
          width: 256,
          height: 256,
        },
      };
    });
  };

  return (
    <div style={{ flex: 1, flexDirection: 'column' }}>
      <div
        ref={messageListReferance}
        style={{
          flex: 1,
          // border: '1px solid #FF979A',
          // marginTop: '5px',
          padding: '5px',
          width: '100%',
          height: '500px',
          overflow: 'scroll',
        }}>
        <MessageList
          referance={messageListReferance}
          downButton={false}
          sendMessagePreview={false}
          downButtonBadge={0}
          className="message-list"
          lockable={false}
          toBottomHeight={'100%'}
          dataSource={formattedMessages() as elementsMessageType[]}
        />
      </div>
      <div style={{ flexDirection: 'row' }}>
        <Form
          onFieldsChange={(value) => {
            localStorage[`${chatId}_messageKey`] = value[0].value;
          }}
          name="basic"
          initialValues={{
            message: localStorage.getItem(`${chatId}_messageKey`) || undefined,
          }}
          onFinish={onSend}
          form={form}
          onFinishFailed={onFinishFailed}>
          <Form.Item
            name="message"
            rules={[{ required: true, message: 'Please input your message!' }]}>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: '10px' }}>
              Submit
            </Button>
            <Upload {...imageProps}>
              <Button icon={<UploadOutlined />}>Click to IMAGEUpload</Button>
            </Upload>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};


const useStyles = makeStyles(() => {
  return {
    messageList: {
      // border: '1px solid #FF979A',
      // marginTop: '5px',
      padding: '15px 5px',
      width: '100%',
      // height: '100px',
      flex: '1 1 auto',
      overflow: 'auto',
      flexDirection: 'row',
      backgroundColor: '#f4f4fa',
    }
  }
})
export const SingleChatLazyLoad = ({
                                     messages,
                                     chatId,
                                     sendMessage,
                                     inputKeywordEl,
                                     inputHelpSelectQuestionsEl,
                                     fetchMoreMessage = null,
                                   }: SingleChatLazyLoadProps): JSX.Element => {
  const classes = useStyles()
  const {uploadS3Image} = useS3Image();
  const s3 = new AWS.S3();
  const [form] = Form.useForm();
  const [chatImageArr, setChatImageArr] = useState<chatImageType[] | null>([]);
  const messageListReferance = useRef<any>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const timeoutRef = useRef<any>(null);
  const currentOffsetRef = useRef<number>(messages.length);

  useEffect(() => {
    const scroll =
        messageListReferance?.current?.scrollHeight -
        messageListReferance?.current?.clientHeight;
    messageListReferance?.current?.scrollTo(0, scroll);
  }, []);

  useEffect(() => {
    currentOffsetRef.current = -1;
    const message = localStorage.getItem(`${chatId}_messageKey`) || undefined;
    if (!!message) {
      form.setFieldsValue({
        message
      })
    } else {
      form.resetFields()
    }
  }, [chatId]);

  useEffect(() => {
    if (!fetchMoreMessage) {
      return
    }
    if (!!timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    if (!messages || (!!messages && messages.length == 0)) {
      if (!!observerRef.current) {
        observerRef.current.disconnect()
      }
      return;
    }
    timeoutRef.current = setTimeout(() => {
      if (!!observerRef.current) {
        observerRef.current.disconnect()
      }
      const listMessageEl = messageListReferance.current;
      const observer = new IntersectionObserver((entries, o) => {
        const lastMessage = entries[0]
        if (!lastMessage.isIntersecting) {
          return
        }

        o.unobserve(lastMessage.target)

        currentOffsetRef.current = messages.length;
        fetchMoreMessage({
          variables: {
            offset: currentOffsetRef.current,
            chatId: chatId,
            text: inputKeywordEl.current?.value ?? null,
            helpQuestion: inputHelpSelectQuestionsEl.current?.value ? inputHelpSelectQuestionsEl.current?.value as HelpQuestion : null,
          }
        });
      }, {
        root: listMessageEl,
        threshold: 0.5
      })
      observerRef.current = observer
      const oldMessageEl = listMessageEl?.querySelector(".rce-container-mbox:first-child");
      if (oldMessageEl) {
        observer.observe(oldMessageEl)
      }
    }, 0)

    return () => {
      if (!!observerRef.current) {
        observerRef.current.disconnect();
      }
      if (!!timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [messages]);

  const onSend = async (e) => {
    const { message } = e;
    await sendMessage({
      variables: {
        chatId,
        text: message,
        messageType: MessageType.TEXT,
      },
    });
    form.resetFields();
    localStorage.removeItem(`${chatId}_messageKey`);
  };

  const customerAvatarUrl =
      'https://s3-ap-northeast-1.amazonaws.com/share-buggy/profilePicture.png';

  const imageProps: UploadProps = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file) {
      return new Promise(() => {
        const reader = new FileReader();
        const options = {
          maxSizeMB: 0.2,
          maxWidthOrHeight: 520,
          useWebWorker: true,
        };
        imageCompression(file, options).then((compressedFile) => {
          reader.readAsDataURL(compressedFile);
          reader.onload = () => {
            const img = document.createElement('img');
            img.src = reader.result as string;
            uploadS3Image({
              uri: img.src,
              meId: messages[0]?.user?.id ? messages[0]?.user?.id : chatId,
              chatId: chatId,
              isPublic: false,
            });
          };
        });
      });
    },
  };

  let messagesImageArr: chatImageType[] = [];
  useEffect(() => {
    messages.map((m) => {
      let base64ImageDataSource: string = '';
      if (m.imageContent?.imageKey && m.imageContent?.imageBucket) {
        const params = {
          Key: m.imageContent?.imageKey,
          Bucket: m.imageContent?.imageBucket,
        };
        s3.getObject(params, (err, data) => {
          let imageData: AWS.S3.GetObjectOutput | undefined = {};

          if (err) {
            console.log('Err: getObject failed :' + err);
          } else {
            imageData = data;
            const buffer = Buffer.from(imageData.Body as any);
            const base64ImageData = buffer.toString('base64');
            base64ImageDataSource = 'data:image/png;base64,' + base64ImageData;
            messagesImageArr.push({
              key: m.imageContent?.imageKey,
              imageDataSource: base64ImageDataSource,
            });
            if (chatImageArr) {
              setChatImageArr([...chatImageArr, ...messagesImageArr]);
            } else {
              setChatImageArr(messagesImageArr);
            }
          }
        });
      }
    });
  }, [messages]);

  const formattedMessages = () => {
    return messages.map((m, i) => {
      const imageUrl = chatImageArr?.find(
          (img) => img.key === m.imageContent?.imageKey,
      );
      const dateString = moment(m.createdAt).format('YYYY/MM/DD HH:mm');
      return {
        id: i,
        position: m.user.role === Role.SYSTEM_ADMIN ? 'right' : 'left',
        title: m.user.role === Role.SYSTEM_ADMIN ? 'Admin' :<Link to={{ pathname: "/customers",
          state: { params: `${m?.user?.firstname}${m?.user?.lastname}`, email: m?.user?.email } }}>
          {m?.user?.lastname}{m?.user?.firstname}
        </Link>,
        titleColor: m.user.role === Role.SYSTEM_ADMIN ? '#191970' : '#FF979A',
        text: m.text,
        type: m.text ? 'text' : 'photo',
        date: new Date(),
        dateString: dateString,
        avatar:
            m.user.role === Role.SYSTEM_ADMIN ? undefined : customerAvatarUrl,
        data: {
          uri: imageUrl?.imageDataSource,
          width: 256,
          height: 256,
        },
      };
    });
  };

  return (
      <>
        <MessageList
            referance={messageListReferance}
            downButton={false}
            sendMessagePreview={false}
            downButtonBadge={0}
            className={classes.messageList}
            lockable={true}
            toBottomHeight={'100%'}
            dataSource={formattedMessages() as elementsMessageType[]}
        />
        <div style={{flexDirection: 'row', padding: '0 10px', backgroundColor: '#f4f4fa', marginTop: 'auto', flex: '0 0 auto'}}>
          <Form
              onFieldsChange={(value) => {
                  if (!!value[0].value) {
                      localStorage[`${chatId}_messageKey`] = value[0].value;
                  }
              }}
              name="basic"
              onFinish={onSend}
              form={form}
              onFinishFailed={onFinishFailed}>
            <Form.Item
                name="message"
                style={{ margin: 0, backgroundColor: '#FFF', borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}
                rules={[{required: true, message: 'Please input your message!'}]}>
              <TextArea autoSize={{ minRows: 2, maxRows: 6 }} style={{ border: 'none', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', outline: 'none', boxShadow: 'none', padding: '6px 12px' }} placeholder="Message..."/>
            </Form.Item>
            <Form.Item style={{ backgroundColor: '#FFF', padding: '10px', marginBottom: '10px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Upload {...imageProps}>
                  <Button style={{ border: 'none' }}
                          icon={<UploadOutlined style={{ fontSize: '22px', color: '#6b7280' }}/>}>
                  </Button>
                </Upload>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{borderRadius: '4px', fontSize: '15px', backgroundColor: '#fa4e69'}} icon={<SendOutlined />}>
                  Send
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </>
  );
};

export type SingleChatLazyLoadProps = {
  messages: {
    id: any;
    user: {
      id: string;
      firstname: string;
      lastname: string;
      profileImageUrl?: string | null;
      role: Role | null;
      email?: string;
    };
    text: string;
    createdAt: string;
    imageContent?: {
      imageKey: string | null;
      imageBucket: string | null;
    } | null;
  }[];
  chatId: string;
  receiveMessage?: any;
  sendMessage: (v: {
    variables: { chatId: string; text: string; messageType: MessageType };
  }) => Promise<void>;
  fetchMoreMessage?: ((v: {
    variables: any
  }) => void) | null;
  inputKeywordEl: any;
  inputHelpSelectQuestionsEl: any;
};
