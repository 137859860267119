import {
    gql,
    NetworkStatus,
    useMutation,
    useQuery,
    useLazyQuery,
    useSubscription,
    useApolloClient
} from '@apollo/client';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {SingleChatLazyLoad} from '@src/components/Chats/SingleChatUI';
import {
    ME,
    FindMessagesPage,
    FindMessagesPageVariables,
    FindChatsPage,
    FindChatsPage_chatsPage, HelpQuestion,
} from '@src/generated/graphql';
import {dateDisplay} from '@src/utils';
import React, {useEffect, useRef, useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select, TextField} from '@material-ui/core';
import {HelpQuestionContents} from '@src/contents/helpQuestion';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {DisconnectOutlined, LeftOutlined} from "@ant-design/icons";
import Tooltip from "@material-ui/core/Tooltip";
import useWindowDimensions from "@src/hooks/useWindowDimensions";

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiInputBase-input': {
            padding: '6px 12px',
            backgroundColor: '#FFF',
            outline: 'none',
            border: 'none',
            boxShadow: 'none'
        },
    },
}));

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    customerList: {
        // overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #ccc',
        borderTop: 'none',
        width: '27vw',
    },
    public: {
        display: 'flex',
        flexDirection: 'column',
        borderWidth: '1px',
        borderColor: '#ccc',
        borderRightStyle: 'solid',
        borderLeft: 'none',
        borderBottomStyle: 'solid',
        width: '73vw',
    },
    chat: {
        // overflow: 'auto',
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'flex-end',
        // flexGrow: 1,
    },
    date: {
        color: '#ccc',
        fontSize: '0.7em',
    },
    noChat: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column',
        fontSize: '16px',
        color: '#9ca3af',
        backgroundColor: '#fff'
    }
};

const FIND_CHATS_PAGE_QUERY = gql`
    query FindChatsPage($text: String, $helpQuestion: HelpQuestion, $allMember: Boolean, $inactiveUser: Boolean, $limit: Int!, $offset: Int!) {
        chatsPage(text: $text, helpQuestion: $helpQuestion, allMember: $allMember, inactiveUser: $inactiveUser, limit: $limit, offset: $offset) {
            id
            name
            createdAt
            lastSeenByAdmin
            lastSeenByUser
            latestMessageTime
            newMessagesCount
        }
    }
`;

const FIND_CHAT_QUERY = gql`
    query FindChat($chatId: ID!) {
        chat(chatId: $chatId) {
            id
            createdAt
            updatedAt
            name
            lastSeenByAdmin
            lastSeenByUser
            latestMessageTime
        }
    }
`;

const FIND_MESSAGE_PAGE_QUERY = gql`
    query FindMessagesPage($text: String, $helpQuestion: HelpQuestion, $chatId: ID!, $limit: Int!, $offset: Int!) {
        messagesPage(text: $text, helpQuestion: $helpQuestion, chatId: $chatId, limit: $limit, offset: $offset){
            id
            imageContent {
                imageKey
                imageBucket
            }
            text
            messageType
            createdAt
            user {
                id
                firstname
                lastname
                profileImageUrl
                role
                email
            }
            chat {
                id
                name
                lastSeenByAdmin
                lastSeenByUser
                latestMessageTime
            }
        }
    }
`;

const NEW_MESSAGE = gql`
    subscription NEW_MESSAGE {
        newMessage {
            id
            imageContent {
                imageKey
                imageBucket
            }
            text
            messageType
            createdAt
            user {
                id
                firstname
                lastname
                profileImageUrl
                role
            }
            chat {
                id
                name
                lastSeenByAdmin
                lastSeenByUser
            }
        }
    }
`;

const SEEN_BY_ADMIN = gql`
    mutation SEEN_BY_ADMIN($chatId: ID!) {
        seenByAdmin(chatId: $chatId) {
            id
        }
    }
`;
export const SEND_MESSAGE = gql`
    mutation SEND_MESSAGE2(
        $chatId: ID!
        $text: String
        $messageType: MessageType!
        $imageKey: String
        $imageBucket: String
    ) {
        sendMessage(
            chatId: $chatId
            text: $text
            messageType: $messageType
            imageKey: $imageKey
            imageBucket: $imageBucket
        ) {
            __typename
            id
        }
    }
`;

const ME_QUERY = gql`
    query ME {
        me {
            id
            email
            profileImageUrl
        }
    }
`;

const ChatsRowPerPage = 20
const MessagesRowPerPage = 40

export const Chats = (props: any): JSX.Element => {
    const apolloClient = useApolloClient();
    const chatIdFromRow = props.location.pathname.replace('chats', '').replaceAll('/', '');
    const inputKeywordEl = useRef<HTMLInputElement>(null);
    const inputHelpSelectQuestionsEl = useRef<HTMLInputElement>(null);
    const inactiveUser = useRef<boolean>(false);
    const [setSeenByAdmin] = useMutation(SEEN_BY_ADMIN);
    const [sendMessage] = useMutation(SEND_MESSAGE);
    const [_, setReceiveMesssage] = useState<any>();
    const [visible, setVisible] = useState<boolean>(true);
    const chatIDRef = useRef<string | null>(null)
    const listChatsRef = useRef<any>(null)
    const observerRef = useRef<IntersectionObserver | null>(null);
    const timeoutRef = useRef<any>(null);
    const [activeMessage, setActiveMessage] = useState<string>('');
    const { width } = useWindowDimensions();
    const {
        loading: meLoading,
        error: meError,
        data: meData,
    } = useQuery<ME>(ME_QUERY);

    const [findChatById, {
        data: chatData,
    }] = useLazyQuery<any>(FIND_CHAT_QUERY, {
        fetchPolicy: 'network-only'
    });

    const {
        error: chatsPageError,
        data: chatsPageData,
        refetch: chatsPageDataRefetch,
        networkStatus: chatsPageNetworkStatus,
        fetchMore: fetchMoreChatsPage
    } = useQuery<FindChatsPage, any>(FIND_CHATS_PAGE_QUERY, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
        variables: {
            offset: 0,
            limit: ChatsRowPerPage,
            text: inputKeywordEl.current?.value || '',
            helpQuestion: inputHelpSelectQuestionsEl?.current?.value ?
                inputHelpSelectQuestionsEl?.current?.value as HelpQuestion : null,
            allMember: visible,
            inactiveUser: inactiveUser.current,
        }
    });

    const [findMessagePage, {
        data: messagesPageData,
        loading: findMessagesPageLoading,
        fetchMore: fetchMoreMessagesPage
    }] = useLazyQuery<FindMessagesPage, FindMessagesPageVariables>(FIND_MESSAGE_PAGE_QUERY, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (!!timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        if (!!observerRef.current) {
            observerRef.current.disconnect()
        }
        timeoutRef.current = setTimeout(() => {
            const elements = listChatsRef.current?.querySelectorAll(`.MuiListItem-root:nth-child(${ChatsRowPerPage}n)`);

            if (elements) {
                observerRef.current = new IntersectionObserver((entries, o) => {
                    const entry = entries[0];
                    if (!entry.isIntersecting) {
                        return;
                    }
                    const index = entry.target.getAttribute("data-index")

                    const offset = !!index ? +index + 1 : 0;
                    fetchMoreChatsPage({
                        variables: {
                            offset: offset,
                            text: inputKeywordEl.current?.value ?? null,
                            helpQuestion: inputHelpSelectQuestionsEl?.current?.value ?
                                inputHelpSelectQuestionsEl?.current?.value as HelpQuestion : null,
                            allMember: visible,
                            inactiveUser: inactiveUser.current,
                        }
                    })
                    o.unobserve(entry.target)
                }, {
                    root: listChatsRef.current,
                    threshold: 0.5
                });
                for (let i = 0; i < elements.length; i++) {
                    observerRef.current.observe(elements[i])
                }
            }
        }, 0)
        return () => {
            if (!!timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
            if (!!observerRef.current) {
                observerRef.current.disconnect()
            }
        }
    }, [chatsPageData]);

    const {error: subscriptionError} = useSubscription(NEW_MESSAGE, {
        onSubscriptionData: ({client, subscriptionData}) => {
            const {newMessage} = subscriptionData.data;
            if (newMessage.user.role !== 'SYSTEM_ADMIN') {
                props.showAlert(
                    `${
                        newMessage.user.firstname + newMessage.user.lastname
                    }さんから新しいメッセージが来ました。`,
                    'warning',
                );
            }
            const defaultTextSearch = '';
            const defaultHelpQuestions = 'null';
            const defaultInactiveUser = false;
            // Update cache
            client.cache.modify({
                fields: {
                    messagesPage(existingMessagesRefs = {}) {
                        const key = `${newMessage.chat.id}` +
                            `_${defaultTextSearch}` +
                            `_${defaultHelpQuestions}`;
                        // get the point from new Message cache
                        const newMessageRef = client.cache.writeFragment({
                            data: newMessage,
                            fragment: gql`
                                fragment NewMessage on Message {
                                    id
                                    imageContent {
                                        imageKey
                                        imageBucket
                                    }
                                    text
                                    messageType
                                    createdAt
                                    user {
                                        id
                                        firstname
                                        lastname
                                        profileImageUrl
                                        role
                                    }
                                    chat {
                                        id
                                    }
                                }
                            `
                        });

                        let oldMessages = []
                        if (!!existingMessagesRefs[key]) {
                            oldMessages = existingMessagesRefs[key]
                        }
                        // add new message to store
                        existingMessagesRefs = {
                            ...existingMessagesRefs,
                            [key]: [newMessageRef, ...oldMessages]
                        }
                        return existingMessagesRefs;
                    },
                    chatsPage(existingChatsRefs = {}, {readField}) {
                        const {chat} = newMessage;

                        const key = `${defaultTextSearch}` +
                            `_${defaultHelpQuestions}` +
                            `_${visible}` +
                            `_${defaultInactiveUser}`;

                        let messagesCountOld = 0;

                        let oldChat: any[] = [];
                        if (!!existingChatsRefs[key]) {
                            oldChat = [...existingChatsRefs[key]]
                        }

                        for (let i = 0; i < oldChat.length; i++) {
                            if (readField('id', oldChat[i]) === chat.id) {
                                // increase number of new messages
                                messagesCountOld = readField('newMessagesCount', oldChat[i]) as number;
                                // remove old chat
                                oldChat.splice(i, 1);
                                break;
                            }
                        }
                        // get the point from new chat cache (override the '__typename' field value to 'ChatInfo')
                        const newChatRef = client.cache.writeFragment({
                            data: {...chat, __typename: "ChatInfo", newMessagesCount: messagesCountOld + 1},
                            fragment: gql`
                                fragment NewChat on ChatInfo {
                                    id
                                    name
                                    createdAt
                                    lastSeenByAdmin
                                    lastSeenByUser
                                    latestMessageTime
                                    newMessagesCount
                                }
                            `
                        });
                        // add new chat to store
                        existingChatsRefs = {
                            ...existingChatsRefs,
                            [key]: [newChatRef, ...oldChat]
                        };
                        return existingChatsRefs;
                    }
                }
            })
        },
    })
    useEffect(() => {
        if (subscriptionError) {
            console.error("Failed to call subscription", subscriptionError)
        }
    }, [subscriptionError]);

    const allMember = (e) => {
        e.preventDefault();
        chatsPageDataRefetch({
            offset: 0,
            limit: ChatsRowPerPage,
            text: inputKeywordEl.current?.value ?? null,
            helpQuestion: inputHelpSelectQuestionsEl?.current?.value ?
                inputHelpSelectQuestionsEl?.current?.value as HelpQuestion : null,
            allMember: !visible,
            inactiveUser: inactiveUser.current,
        });
        setVisible((visible) => !visible);
    };

    const chatNameFn = ({chatId, chatsPageData}) => {
        if (chatId === chatIdFromRow && chatData?.chat) {
            return chatData.chat.name
        }

        const chatName = chatsPageData?.chatsPage.find((c) => c.id === chatIdFromRow)?.name;
        const userName = chatsPageData?.chatsPage.find((c) => c.id === chatId)?.name;
        if (userName) {
            return userName;
        }
        if (chatName) {
            return chatName;
        }
        return 'プライベートチャット';
    };

    const chatMessagesFn = ({messagesPageData}) => {
        const messages = messagesPageData?.messagesPage
        if (messages) {
            return messages
        }
        // return <div>No chat is selected</div>;
        return null;
    };

    const findUserChat = chatsPageData?.chatsPage?.find(
        (data) => data?.id === chatIdFromRow,
    );

    useEffect(() => {
        if (chatIdFromRow) {
            chatIDRef.current = chatIdFromRow;
            findChatById({
                variables: {
                    chatId: chatIdFromRow
                }
            })
            findMessagePage({
                variables: {
                    text: '',
                    helpQuestion: null,
                    chatId: chatIdFromRow,
                    limit: MessagesRowPerPage,
                    offset: 0
                }
            })
            setSeenByAdmin({
                variables: {
                    chatId: chatIdFromRow,
                },
            });
        }
    }, []);

    const handleSearch = (e) => {
        e.preventDefault();
        chatsPageDataRefetch({
            limit: ChatsRowPerPage,
            offset: 0,
            text: inputKeywordEl.current?.value ?? null,
            helpQuestion: inputHelpSelectQuestionsEl?.current?.value ?
                inputHelpSelectQuestionsEl?.current?.value as HelpQuestion : null,
            allMember: visible,
            inactiveUser: inactiveUser.current,
        });
    }

    const handleSearchInactiveUser = (e) => {
        inactiveUser.current = e.target.checked;
        chatsPageDataRefetch({
            limit: ChatsRowPerPage,
            offset: 0,
            text: inputKeywordEl.current?.value ?? null,
            helpQuestion: inputHelpSelectQuestionsEl?.current?.value ?
                inputHelpSelectQuestionsEl?.current?.value as HelpQuestion : null,
            allMember: visible,
            inactiveUser: inactiveUser.current,
        });
    }

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: '1rem',
        },
    }))(Tooltip);

    const loadingTiming = chatsPageNetworkStatus === NetworkStatus.setVariables;

    if (meLoading) return <div>Loading...</div>;
    if (!meData) return <div>Loading...</div>;
    if (!chatsPageData) return <div>Loading...</div>;
    if (meError) return <div>`Error! ${meError.message}`</div>;
    if (chatsPageError) return <div>`Error! ${chatsPageError.message}`</div>;

    if (width < 678 && activeMessage === '') {
        return (
          <div style={styles.container}>
              <div style={{
                  height: 'calc(100vh - 126px)',
                  // overflow: 'scroll',
                  display: 'flex',
                  flexDirection: 'column',
                  border: 'none',
                  width: '100%',
              }}>
                  <AppBar position="static" color="default" style={{ boxShadow: 'none', borderTop: '1px solid #cccccc', backgroundColor: '#f4f4fa', zIndex: '10' }}>
                      <Toolbar style={{ padding: '16px' }}>
                          <Typography variant="h6" color="inherit" style={{ width: '100%' }}>
                              <div style={{ fontSize: '14px', display: 'flex', flexDirection: width < 1130 ? 'column' : 'row' }}>
                                <span>
                                    {visible ? '全ユーザー' : 'メッセージユーザー'}
                                </span>
                                  <button
                                    style={{
                                        fontSize: '15px',
                                        marginLeft: width < 1130 ? '0' : '15px',
                                        marginTop: width < 1130 ? '10px' : '0',
                                        border: 'none',
                                        borderRadius: '4px',
                                        color: '#374151',
                                        padding: '4px 14px',
                                        backgroundColor: '#fff',
                                        cursor: 'pointer',
                                        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'}}
                                    onClick={allMember}>
                                      切替
                                  </button>
                              </div>
                              <div style={{ marginTop: '10px' }}>
                                  <FormControlLabel
                                    control={<Checkbox style={{ fontSize: '14px' }} onChange={handleSearchInactiveUser} name="inactiveUser"/>}
                                    label={<span style={{ fontSize: '14px' }}>非活性ユーザー</span>}
                                  />
                              </div>
                              <div style={{
                                  marginTop: '10px'
                              }}>
                                  <SearchTextField
                                    handleSearch={handleSearch}
                                    inputKeywordEl={inputKeywordEl}
                                    inputHelpSelectQuestionsEl={inputHelpSelectQuestionsEl}
                                  />
                              </div>
                          </Typography>
                      </Toolbar>
                  </AppBar>
                  {
                      <List style={{overflowY: 'auto', padding: '0 10px', backgroundColor: '#f4f4fa'}} ref={listChatsRef}>
                          {
                              loadingTiming ? <div style={{marginTop: '10px', marginLeft: '10px'}}>検索中</div> :
                                chatsPageData?.chatsPage?.map((chat: FindChatsPage_chatsPage, idx: number) =>
                                  <ListItem
                                    style={{
                                        position: 'relative',
                                        marginBottom: '10px',
                                        padding: '16px',
                                        backgroundColor: '#FFF',
                                        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        overflow: 'hidden',
                                    }}
                                    button
                                    data-index={idx}
                                    key={idx}
                                    onClick={async () => {
                                        const messageValueFn = ({chatId}) => {
                                            const messageValue = localStorage.getItem(`${chatId}_messageKey`)
                                              ? localStorage.getItem(`${chatId}_messageKey`)
                                              : '';
                                            return messageValue;
                                        };
                                        setReceiveMesssage(messageValueFn({chatId: chat?.id}));
                                        setActiveMessage(chat?.id);
                                        await setSeenByAdmin({
                                            variables: {
                                                chatId: chat?.id,
                                            },
                                        });
                                        if (!!chat?.id) {
                                            findMessagePage({
                                                variables: {
                                                    text: inputKeywordEl.current?.value ?? null,
                                                    helpQuestion: inputHelpSelectQuestionsEl.current?.value ? inputHelpSelectQuestionsEl.current?.value as HelpQuestion : null,
                                                    chatId: chat.id,
                                                    limit: MessagesRowPerPage,
                                                    offset: 0
                                                }
                                            })
                                            // update cache
                                            apolloClient.cache.modify({
                                                fields: {
                                                    chatsPage(existingChatsRefs = {}, {readField}) {
                                                        const key = `${inputKeywordEl.current?.value ?? null}` +
                                                          `_${inputHelpSelectQuestionsEl?.current?.value ?
                                                            inputHelpSelectQuestionsEl?.current?.value as HelpQuestion : null}` +
                                                          `_${visible}` +
                                                          `_${inactiveUser.current}`;

                                                        let oldChat: any[] = [];
                                                        if (!!existingChatsRefs[key]) {
                                                            oldChat = [...existingChatsRefs[key]]
                                                        }
                                                        let chatIndex = -1;
                                                        for (let i = 0; i < oldChat.length; i++) {
                                                            if (readField('id', oldChat[i]) === chat.id) {
                                                                chatIndex = i;
                                                                break;
                                                            }
                                                        }
                                                        // get the point from new chat cache (override the '__typename' field value to 'ChatInfo')
                                                        const newChatRef = apolloClient.cache.writeFragment({
                                                            data: {
                                                                ...chat,
                                                                __typename: "ChatInfo",
                                                                newMessagesCount: 0
                                                            },
                                                            fragment: gql`
                                                                    fragment NewChat on ChatInfo {
                                                                        id
                                                                        name
                                                                        createdAt
                                                                        lastSeenByAdmin
                                                                        lastSeenByUser
                                                                        latestMessageTime
                                                                        newMessagesCount
                                                                    }
                                                                `
                                                        });
                                                        if (chatIndex > -1) {
                                                            oldChat.splice(chatIndex, 1, newChatRef);
                                                            existingChatsRefs = {
                                                                ...existingChatsRefs,
                                                                [key]: [...oldChat]
                                                            };
                                                        }
                                                        return existingChatsRefs;
                                                    }
                                                }
                                            })
                                        }
                                        chatIDRef.current = chat?.id || '';
                                    }}>
                                      <ListItemAvatar style={{
                                          maxWidth: '50px',
                                          width: '100%',
                                          display: 'flex'
                                      }}>
                                          <Avatar>
                                              {chat?.name?.substring(0, 1)}
                                          </Avatar>
                                      </ListItemAvatar>
                                      <div style={{
                                          flex: 1,
                                          position: 'relative',
                                          width: 'calc(100% - 50px)',
                                          display: 'flex',
                                          flexDirection: 'column',
                                      }}>
                                          <Badge
                                            style={{
                                                position: 'relative',
                                                width: '100%'
                                            }}
                                            color="secondary"
                                            badgeContent={
                                                chatIDRef.current === chat?.id
                                                  ? null
                                                  : chat?.newMessagesCount
                                            }>
                                              <ListItemText primary={
                                                  <LightTooltip title={chat?.name} placement="bottom">
                                                      <Typography
                                                        noWrap
                                                        style={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            width: '100%',
                                                            display: 'block'
                                                        }}>
                                                          {chat?.name}
                                                      </Typography>
                                                  </LightTooltip>
                                              }/>
                                          </Badge>
                                          <p style={styles.date}>{dateDisplay(chat?.latestMessageTime)}</p>
                                      </div>
                                      {
                                          activeMessage && activeMessage === chat?.id ?
                                            <div style={{ position: 'absolute', backgroundColor: '#fa4e69', right: 0, width: '5px', height: '100%' }}></div>
                                            : <></>
                                      }
                                  </ListItem>
                                )
                          }
                      </List>
                  }
              </div>
          </div>
        )
    } else if (width < 678 && activeMessage !== '') {
        return (
          <div style={styles.container}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                border: 'none',
                height: 'calc(100vh - 126px)',
                width: '100%',
            }}>
                <AppBar position="static" color="default" style={{ boxShadow: 'none', borderTop: '1px solid #cccccc',backgroundColor: '#f4f4fa', borderBottom: '1px solid #cccccc', flex: "0 0 auto"}}>
                    <Toolbar style={{
                        display:'flex',
                        gap: '10px',
                        alignItems: 'center',
                        flexDirection: 'row'
                    }}>
                        <button style={{
                            width: '36px',
                            border: 'none',
                            height: '36px',
                            marginRight: '10px',
                            boxShadow: 'none',
                            whiteSpace: 'nowrap',
                            borderRadius: '4px',
                            color: '#374151',
                            backgroundColor: '#e5e7eb',
                        }} onClick={() => setActiveMessage('')}>
                            <LeftOutlined style={{fontSize: '16px'}}/>
                        </button>
                        <Typography variant="h6" color="inherit" style={{fontSize: '16px', fontWeight: '600'}}>
                            {chatNameFn({chatId: chatIDRef.current, chatsPageData})}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <RenderSingleChat
                  fetchMoreMessagesPage={fetchMoreMessagesPage}
                  findUserChat={findUserChat}
                  loading={findMessagesPageLoading}
                  sendMessage={sendMessage}
                  chatId={chatIDRef.current}
                  messages={chatMessagesFn({messagesPageData})}
                  inputKeywordEl={inputKeywordEl}
                  inputHelpSelectQuestionsEl={inputHelpSelectQuestionsEl}
                />
            </div>
          </div>
        )
    }
    return (
      <div style={styles.container}>
            <div style={{...styles.customerList, height: 'calc(100vh - 174px)', }}>
                <AppBar position="static" color="default" style={{ boxShadow: 'none', borderTop: '1px solid #cccccc', backgroundColor: '#f4f4fa', zIndex: '10' }}>
                    <Toolbar style={{ padding: '16px' }}>
                        <Typography variant="h6" color="inherit" style={{ width: '100%' }}>
                            <div style={{ fontSize: '14px', display: 'flex', flexDirection: width < 1130 ? 'column' : 'row' }}>
                                <span>
                                    {visible ? '全ユーザー' : 'メッセージユーザー'}
                                </span>
                                <button
                                    style={{
                                        fontSize: '15px',
                                        marginLeft: width < 1130 ? '0' : '15px',
                                        marginTop: width < 1130 ? '10px' : '0',
                                        border: 'none',
                                        borderRadius: '4px',
                                        color: '#374151',
                                        padding: '4px 14px',
                                        backgroundColor: '#fff',
                                        cursor: 'pointer',
                                        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'}}
                                    onClick={allMember}>
                                    切替
                                </button>
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <FormControlLabel
                                    control={<Checkbox style={{ fontSize: '14px' }} onChange={handleSearchInactiveUser} name="inactiveUser"/>}
                                    label={<span style={{ fontSize: '14px' }}>非活性ユーザー</span>}
                                />
                            </div>
                            <div style={{
                                marginTop: '10px'
                            }}>
                                <SearchTextField
                                    handleSearch={handleSearch}
                                    inputKeywordEl={inputKeywordEl}
                                    inputHelpSelectQuestionsEl={inputHelpSelectQuestionsEl}
                                />
                            </div>
                        </Typography>
                    </Toolbar>
                </AppBar>
                {
                    <List style={{overflowY: 'auto', padding: '0 10px', backgroundColor: '#f4f4fa'}} ref={listChatsRef}>
                        {
                            loadingTiming ? <div style={{marginTop: '10px', marginLeft: '10px'}}>検索中</div> :
                                chatsPageData?.chatsPage?.map((chat: FindChatsPage_chatsPage, idx: number) =>
                                    <ListItem
                                        style={{
                                            position: 'relative',
                                            marginBottom: '10px',
                                            padding: '16px',
                                            backgroundColor: '#FFF',
                                            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                            overflow: 'hidden',
                                        }}
                                        button
                                        data-index={idx}
                                        key={idx}
                                        onClick={async () => {
                                            const messageValueFn = ({chatId}) => {
                                                const messageValue = localStorage.getItem(`${chatId}_messageKey`)
                                                    ? localStorage.getItem(`${chatId}_messageKey`)
                                                    : '';
                                                return messageValue;
                                            };
                                            setReceiveMesssage(messageValueFn({chatId: chat?.id}));
                                            setActiveMessage(chat?.id);
                                            await setSeenByAdmin({
                                                variables: {
                                                    chatId: chat?.id,
                                                },
                                            });
                                            if (!!chat?.id) {
                                                findMessagePage({
                                                    variables: {
                                                        text: inputKeywordEl.current?.value ?? null,
                                                        helpQuestion: inputHelpSelectQuestionsEl.current?.value ? inputHelpSelectQuestionsEl.current?.value as HelpQuestion : null,
                                                        chatId: chat.id,
                                                        limit: MessagesRowPerPage,
                                                        offset: 0
                                                    }
                                                })
                                                // update cache
                                                apolloClient.cache.modify({
                                                    fields: {
                                                        chatsPage(existingChatsRefs = {}, {readField}) {
                                                            const key = `${inputKeywordEl.current?.value ?? null}` +
                                                                `_${inputHelpSelectQuestionsEl?.current?.value ?
                                                                    inputHelpSelectQuestionsEl?.current?.value as HelpQuestion : null}` +
                                                                `_${visible}` +
                                                                `_${inactiveUser.current}`;

                                                            let oldChat: any[] = [];
                                                            if (!!existingChatsRefs[key]) {
                                                                oldChat = [...existingChatsRefs[key]]
                                                            }
                                                            let chatIndex = -1;
                                                            for (let i = 0; i < oldChat.length; i++) {
                                                                if (readField('id', oldChat[i]) === chat.id) {
                                                                    chatIndex = i;
                                                                    break;
                                                                }
                                                            }
                                                            // get the point from new chat cache (override the '__typename' field value to 'ChatInfo')
                                                            const newChatRef = apolloClient.cache.writeFragment({
                                                                data: {
                                                                    ...chat,
                                                                    __typename: "ChatInfo",
                                                                    newMessagesCount: 0
                                                                },
                                                                fragment: gql`
                                                                    fragment NewChat on ChatInfo {
                                                                        id
                                                                        name
                                                                        createdAt
                                                                        lastSeenByAdmin
                                                                        lastSeenByUser
                                                                        latestMessageTime
                                                                        newMessagesCount
                                                                    }
                                                                `
                                                            });
                                                            if (chatIndex > -1) {
                                                                oldChat.splice(chatIndex, 1, newChatRef);
                                                                existingChatsRefs = {
                                                                    ...existingChatsRefs,
                                                                    [key]: [...oldChat]
                                                                };
                                                            }
                                                            return existingChatsRefs;
                                                        }
                                                    }
                                                })
                                            }
                                            chatIDRef.current = chat?.id || '';
                                        }}>
                                        <ListItemAvatar style={{
                                            maxWidth: '50px',
                                            width: '100%',
                                            display: 'flex'
                                        }}>
                                            <Avatar>
                                                {chat?.name?.substring(0, 1)}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <div style={{
                                            flex: 1,
                                            position: 'relative',
                                            width: 'calc(100% - 50px)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                            <Badge
                                              style={{
                                                  position: 'relative',
                                                  width: '100%'
                                              }}
                                              color="secondary"
                                              badgeContent={
                                                  chatIDRef.current === chat?.id
                                                    ? null
                                                    : chat?.newMessagesCount
                                              }>
                                                <ListItemText primary={
                                                    <LightTooltip title={chat?.name} placement="bottom">
                                                        <Typography
                                                          noWrap
                                                          style={{
                                                              overflow: 'hidden',
                                                              textOverflow: 'ellipsis',
                                                              width: '100%',
                                                              display: 'block'
                                                          }}>
                                                            {chat?.name}
                                                        </Typography>
                                                    </LightTooltip>
                                                }/>
                                            </Badge>
                                            <p style={styles.date}>{dateDisplay(chat?.latestMessageTime)}</p>
                                        </div>
                                        {
                                            activeMessage && activeMessage === chat?.id ?
                                              <div style={{ position: 'absolute', backgroundColor: '#fa4e69', right: 0, width: '5px', height: '100%' }}></div>
                                              : <></>
                                        }
                                    </ListItem>
                                )
                        }
                    </List>
                }
            </div>
            <div style={{...styles.public, height: 'calc(100vh - 174px)',}}>
                <AppBar position="static" color="default" style={{ boxShadow: 'none', borderTop: '1px solid #cccccc' ,backgroundColor: '#f4f4fa', borderBottom: '1px solid #cccccc', flex: "0 0 auto"}}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit" style={{ fontSize: '16px', fontWeight: '600'}}>
                            {chatNameFn({chatId: chatIDRef.current, chatsPageData})}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <RenderSingleChat
                  fetchMoreMessagesPage={fetchMoreMessagesPage}
                  findUserChat={findUserChat}
                  loading={findMessagesPageLoading}
                  sendMessage={sendMessage}
                  chatId={chatIDRef.current}
                  messages={chatMessagesFn({messagesPageData})}
                  inputKeywordEl={inputKeywordEl}
                  inputHelpSelectQuestionsEl={inputHelpSelectQuestionsEl}
                />
            </div>
        </div>
    );
};

const SearchTextField = ({
                             handleSearch,
                             inputKeywordEl,
                             inputHelpSelectQuestionsEl,
                         }: propsType) => {
    const onPressClear = () => {
        inputKeywordEl.current.value = '';
        inputHelpSelectQuestionsEl.current.value = '';
    };
    const classes = useStyles();
    const { width } = useWindowDimensions();

    return (
        //部分一致検索！
        <>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                <TextField
                  className={classes.root}
                  inputRef={inputKeywordEl}
                  id="field"
                  color="secondary"
                  variant="outlined"
                  label=""
                  style={{
                      width: width < 768 ? '100%' : 'max-content',
                  }}
                />
                <div style={{
                    display: 'flex',
                    flexDirection: width < 768 ? 'column' : 'row',
                    width: width < 768 ? '100%' : 'max-content',
                    gap: '10px',
                    marginTop: '-1px'
                }}>
                    <button
                      style={{
                          width: width < 768 ? '100%' : 'max-content',
                          fontSize: '15px',
                          border: 'none',
                          borderRadius: '4px',
                          backgroundColor: '#fa4e69',
                          color: '#fff',
                          padding: '4px 14px',
                          cursor: 'pointer',
                          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
                      }}
                      onClick={handleSearch}>
                        検索
                    </button>
                    <button
                      style={{
                          fontSize: '15px',
                          border: 'none',
                          borderRadius: '4px',
                          color: '#374151',
                          padding: '4px 14px',
                          backgroundColor: '#fff',
                          cursor: 'pointer',
                          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
                      }}
                      onClick={() => onPressClear()}>
                        クリア
                    </button>
                </div>
            </div>
            <div style={{
                marginTop: '15px',
            }}>
                <FormControl
                  fullWidth
                  style={{
                      marginBottom: '10px',
                  }}>
                    <InputLabel id="demo-simple-select-label">質問検索</InputLabel>
                    <Select
                      defaultValue={''}
                      inputRef={inputHelpSelectQuestionsEl}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="SelectQuestion">
                        <MenuItem value={HelpQuestion.BABYCAR_BROKE}>
                            {HelpQuestionContents[HelpQuestion.BABYCAR_BROKE]}
                        </MenuItem>
                        <MenuItem value={HelpQuestion.BOX_TROUBLE}>
                            {HelpQuestionContents[HelpQuestion.BOX_TROUBLE]}
                        </MenuItem>
                        <MenuItem value={HelpQuestion.LOST_BABYCAR}>
                            {HelpQuestionContents[HelpQuestion.LOST_BABYCAR]}
                        </MenuItem>
                        <MenuItem value={HelpQuestion.OHTER}>
                            {HelpQuestionContents[HelpQuestion.OHTER]}
                        </MenuItem>
                        <MenuItem
                            value={HelpQuestion.SETTLEMENT_TROUBLE}>
                            {HelpQuestionContents[HelpQuestion.SETTLEMENT_TROUBLE]}
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
        </>
    );
};

type propsType = {
    handleSearch: (e: any) => void;
    inputKeywordEl: any;
    inputHelpSelectQuestionsEl: any;
};


const RenderSingleChat = ({
                              chatId,
                              messages,
                              sendMessage,
                              fetchMoreMessagesPage,
                              findUserChat,
                              inputKeywordEl,
                              inputHelpSelectQuestionsEl
                          }: any) => {
    if (!messages && chatId) {
        return <div>Loading...</div>;
    } else if (chatId && !!messages) {
        return (
            <SingleChatLazyLoad
                messages={messages}
                chatId={chatId || findUserChat?.id}
                sendMessage={sendMessage}
                fetchMoreMessage={fetchMoreMessagesPage}
                inputKeywordEl={inputKeywordEl}
                inputHelpSelectQuestionsEl={inputHelpSelectQuestionsEl}
            />
        );
    }

    return <div style={styles.noChat}>
        <DisconnectOutlined style={{fontSize: '80px'}}/>
        No chat is selected
    </div>;
};
