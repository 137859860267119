import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {ValidatorForm} from 'react-material-ui-form-validator';
import {Button, MenuItem, Select, TextField} from '@material-ui/core';
import {createTheme, MuiThemeProvider, makeStyles} from '@material-ui/core/styles';
import ja from 'date-fns/locale/ja';
import moment from 'moment';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import {ReservationRow} from './components/Row';

registerLocale('ja', ja);
type ReservationsComponent1Type = {
  reservationsData: any;
  unlock: (token: any) => Promise<void>;
  adminLock: (token: any) => Promise<void>;
  updateReservation: (reservationId: any, paymentStatus: any) => Promise<void>;
  finish: (reservationId: any) => Promise<void>;
  onChangePage: (_event: any, pageNumber: any) => void;
  onChangeRowsPerPage: (event: any) => void;
  page: number;
  rowsPerPage: number;
  isSystemAdmin: any;
  searchState: any;
  portData: any;
  filterData: any;
  reservationsLoading: any;
  allClear: any;
  searchStatus: any;
  onChangeHandler: any;
  adminLockReservation: (portId: any, doorId: any) => Promise<void>;
  createChat?: (userId: any) => Promise<any>;
};
const inputStyle = {
  marginTop: '1px',
  backgroundColor: '#fff',
  display: 'flex',
  border: '1px solid rgb(0 0 0 / 0.1)',
  boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
  borderRadius: '3px',
  padding: '1px 2px',
};
const ButtonInputStyle = {
  marginTop: '1px',
  backgroundColor: '#fff',
  border: '1px solid rgb(0 0 0 / 0.1)',
  boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
  borderRadius: '3px',
  padding: '4.5px 4px',
};
const itemText = {
  color: '#000000d9',
  fontSize: '12px',
  fontWeight: '600',
};

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {border: '#dedede'},
      inputMarginDense: {paddingTop: '5px', paddingBottom: '5px'},
    },
    MuiIconButton: {root: {borderRadius: '0%'}, colorPrimary: {color: '#FFFFFF'}},
    MuiSelect: {root: {padding: '5px 14px'}},
  },
});

const useStyles = makeStyles({
  spacer: {display: 'none'},
});

export const ReservationsComponent1 = React.memo((
  {
    reservationsData,
    filterData,
    unlock,
    adminLock,
    updateReservation,
    finish,
    onChangePage,
    onChangeRowsPerPage,
    page,
    rowsPerPage,
    isSystemAdmin,
    adminLockReservation,
    createChat,
    portData,
    searchState,
    reservationsLoading,
    onChangeHandler,
    allClear,
    searchStatus,
  }: ReservationsComponent1Type) => {
  const classes = useStyles();
  return (
    <div style={{height: '100%', position: 'relative', opacity: reservationsLoading ? '0.3' : '1'}}>
      <h1>予約一覧</h1>
      <ValidatorForm onSubmit={filterData}>
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
          gap: '12px 0px',
          width: '100%',
          marginBottom: '1em'
        }}>
          <div style={{display: 'flex', gap: '24px'}}>
            <MuiThemeProvider theme={theme}>
              <div style={{display: 'flex', flexDirection: 'column', width: '190px'}}>
                <div style={itemText}>開始時間</div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <DatePicker
                    maxDate={searchState.startTimeTo}
                    style={{...inputStyle, width: '100%'}}
                    locale="ja"
                    selected={searchState.startTimeFrom}
                    selectsStart
                    startDate={moment(searchState.startTimeFrom).toDate()}
                    endDate={moment(searchState.startTimeTo).toDate()}
                    onChange={(date) => onChangeHandler({target: {name: 'startTimeFrom', value: date}})}
                    customInput={<button
                      type={'button'}
                      style={ButtonInputStyle}>{moment(searchState.startTimeFrom).format('YYYY-MM-DD')}</button>}
                  />
                  <div style={{margin: '0 8px'}}>~</div>
                  <DatePicker
                    minDate={searchState.startTimeFrom}
                    style={{...inputStyle, width: '100%'}}
                    locale="ja"
                    selected={searchState.startTimeTo}
                    selectsEnd
                    startDate={moment(searchState.startTimeFrom).toDate()}
                    endDate={moment(searchState.startTimeTo).toDate()}
                    onChange={(date) => onChangeHandler({target: {name: 'startTimeTo', value: date}})}
                    customInput={<button
                      type={'button'}
                      style={ButtonInputStyle}>{moment(searchState.startTimeTo).format('YYYY-MM-DD')}</button>}
                  />
                </div>
              </div>
            </MuiThemeProvider>
            <MuiThemeProvider theme={theme}>
              <div style={{display: 'flex', flexDirection: 'column', width: '190px'}}>
                <div style={itemText}>氏名</div>
                <TextField
                  style={{...inputStyle, width: '100%'}}
                  defaultValue={searchState.name}
                  onChange={onChangeHandler}
                  name="name"
                  margin="dense"
                  variant="outlined"
                />
              </div>
            </MuiThemeProvider>
            <MuiThemeProvider theme={theme}>
              <div style={{display: 'flex', flexDirection: 'column', width: '190px'}}>
                <div style={itemText}>貸し出しポート</div>
                <Select
                  name="portBorrow"
                  style={{...inputStyle, width: '100%'}}
                  variant="outlined"
                  value={searchState.portBorrow}
                  onChange={onChangeHandler}
                >
                  <MenuItem value="no-selection">選択なし</MenuItem>
                  {portData?.ports?.map((port, index) => (
                    <MenuItem key={index} value={port.id}>{port.name}</MenuItem>
                  ))}
                </Select>
              </div>
            </MuiThemeProvider>
            <MuiThemeProvider theme={theme}>
              <div style={{display: 'flex', flexDirection: 'column', width: '190px'}}>
                <div style={itemText}>受取状態</div>
                <Select
                  style={{...inputStyle, width: '100%'}}
                  variant="outlined"
                  value={searchState.status}
                  name="status"
                  onChange={onChangeHandler}
                >
                  <MenuItem value="no-selection">選択なし</MenuItem>
                  <MenuItem value="1">返却済</MenuItem>
                  <MenuItem value="0">貸出中</MenuItem>
                </Select>
              </div>
            </MuiThemeProvider>
          </div>
          <div style={{display: 'flex', gap: '24px'}}>
            <MuiThemeProvider theme={theme}>
              <div style={{display: 'flex', flexDirection: 'column', width: '190px'}}>
                <div style={itemText}>返却時間</div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <DatePicker
                    maxDate={searchState.endTimeTo}
                    style={{...inputStyle, width: '100%'}}
                    locale="ja"
                    selected={searchState.endTimeFrom}
                    selectsStart
                    startDate={moment(searchState.endTimeFrom).toDate()}
                    endDate={moment(searchState.endTimeTo).toDate()}
                    onChange={(date) => onChangeHandler({target: {name: 'endTimeFrom', value: date}})}
                    customInput={<button
                      type={'button'}
                      style={ButtonInputStyle}>{moment(searchState.endTimeFrom).format('YYYY-MM-DD')}</button>}
                  />
                  <div style={{margin: '0 8px'}}>~</div>
                  <DatePicker
                    minDate={searchState.endTimeFrom}
                    style={{...inputStyle, width: '100%'}}
                    locale="ja"
                    selected={searchState.endTimeTo}
                    selectsEnd
                    startDate={moment(searchState.endTimeFrom).toDate()}
                    endDate={moment(searchState.endTimeTo).toDate()}
                    onChange={(date) => onChangeHandler({target: {name: 'endTimeTo', value: date}})}
                    customInput={<button
                      type={'button'}
                      style={ButtonInputStyle}>{moment(searchState.endTimeTo).format('YYYY-MM-DD')}</button>}
                  />
                </div>
              </div>
            </MuiThemeProvider>
            <MuiThemeProvider theme={theme}>
              <div style={{display: 'flex', flexDirection: 'column', width: '190px'}}>
                <div style={itemText}>電話番号</div>
                <TextField
                  style={{...inputStyle, width: '100%'}}
                  value={searchState.phoneNo}
                  onChange={onChangeHandler}
                  name="phoneNo"
                  margin="dense"
                  variant="outlined"
                />
              </div>
            </MuiThemeProvider>
            <MuiThemeProvider theme={theme}>
              <div style={{display: 'flex', flexDirection: 'column', width: '190px'}}>
                <div style={itemText}>返却ポート</div>
                <Select
                  name="portReturn"
                  style={{...inputStyle, width: '100%'}}
                  variant="outlined"
                  value={searchState.portReturn}
                  onChange={onChangeHandler}
                >
                  <MenuItem value="no-selection">選択なし</MenuItem>
                  {portData?.ports?.map((port, index) => (
                    <MenuItem key={index} value={port.id}>{port.name}</MenuItem>
                  ))}
                </Select>
              </div>
            </MuiThemeProvider>
            <MuiThemeProvider theme={theme}>
              <div style={{display: 'flex', flexDirection: 'column', width: '190px'}}>
                <div style={itemText}>メールアドレス</div>
                <TextField
                  style={{...inputStyle, width: '100%'}}
                  defaultValue={searchState.email}
                  onChange={onChangeHandler}
                  name="email"
                  margin="dense"
                  variant="outlined"
                />
              </div>
            </MuiThemeProvider>
          </div>
        </div>
        <Button
          style={{paddingTop: '3px', paddingBottom: '3px'}}
          onClick={allClear}
          variant="outlined"
        >
          クリア
        </Button>
        <Button
          style={{
            marginRight: '3%',
            marginLeft: 10,
            backgroundColor: '#12C670',
            borderColor: '#12C670',
            color: '#FFFFFF',
            padding: '3px',
          }}
          type="submit"
          variant="outlined"
        >
          検索
        </Button>
      </ValidatorForm>
      <div style={{marginTop: '20px', marginBottom: '8px', fontWeight: '600'}}>
        {reservationsData?.reservationsConnection?.item.length === 0 ? '検索結果がありません' : searchStatus}
      </div>
      <Paper style={{width: '100%', overflowX: 'auto', zIndex: 1}} elevation={2}>
        <Table style={{backgroundColor: 'white'}}>
          <TableHead>
            <TableRow>
              <TableCell style={{whiteSpace: 'nowrap'}}>番号</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>名前</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>貸出ポート名</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>貸出ドア番号</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>返却ポート名</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>返却ドア番号</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>受取（返却）完了</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>受取状態</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>返却開始</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>通常価格</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>クーポン</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>支払い状況</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>最終価格</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>開始時間</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>返却時間</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>電話番号</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>プラン名</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>延長回数</TableCell>
              <TableCell style={{whiteSpace: 'nowrap'}}>決済状況</TableCell>
              <TableCell style={{whiteSpace: 'nowrap', visibility: 'hidden'}}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!reservationsLoading && reservationsData?.reservationsConnection?.item?.map((reservation, index) => (
              <ReservationRow
                key={reservation.id}
                index={page * rowsPerPage + index}
                reservation={reservation}
                unlock={unlock}
                adminLock={adminLock}
                finish={finish}
                updateReservation={updateReservation}
                isSystemAdmin={isSystemAdmin}
                adminLockReservation={adminLockReservation}
                createChat={createChat}
              />
            ))}
          </TableBody>
          <TablePagination
            classes={{spacer: classes.spacer}}
            rowsPerPageOptions={[25, 50, 75, 100]}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={reservationsData?.reservationsConnection?.total || 0}
          />
        </Table>
      </Paper>
    </div>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.reservationsData === nextProps.reservationsData &&
    prevProps.reservationsLoading === nextProps.reservationsLoading &&
    prevProps.searchState === nextProps.searchState &&
    prevProps.page === nextProps.page &&
    prevProps.rowsPerPage === nextProps.rowsPerPage &&
    prevProps.portData === nextProps.portData &&
    prevProps.searchStatus === nextProps.searchStatus
  );
});